body {
  background: #2e363f;
  /*background: url("/static/images/skyline.jpg") no-repeat center center fixed;*/
  /*-webkit-background-size: cover;*/
  /*-moz-background-size: cover;*/
  /*-o-background-size: cover;*/
  /*background-size: cover;*/
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Segoe UI Light", "Roboto-Light", "Roboto", sans-serif;
}
.dtw-logo {
  max-height: 30vh;
}
.dtw-logo-white {
  max-height: 35px;
  margin-right: 15px;
}
.brand-mono {
  color: #2e363f;
}
.brand-light {
  color: #46c3ec;
}
.brand-dark {
  color: rgb(59, 100, 175);
}
.jumbotron {
  background-color: #fefefe;
}
.section.light {
  background-color: #efefef;
}
.section.dark {
  background-color: #485058;
  color: white;
}
.section.dark-blue {
  background-color: #234099;
  color: white;
}
.section.lightest {
  background-color: #fff;
  color: #333;
}
.section.mono {
  background-color: #2e363f;
}
.dtw-footer {
  background-color: rgb(59, 100, 175);
  color: white;
  padding: 12px;
}
.testimonial-card img {
  height: 110px;
}
.text-black {
  color: black;
}
td {
  background-color: rgb(255, 255, 255);
}
.invalid-feedback {
  color: red;
  display: block;
}
.valid-feedback {
  color: green;
  display: block;
}
.perk-item {
  border-top: 1px #aaa solid;
  padding: 15px;
  display: block;
}
.progress,
.progress-bar.progress-bar-striped.progress-bar-animated {
  height: 8px;
}
.img.speaker,
.img.organizer {
  width: 110px;
}
.speaker.description-container {
  font-weight: 400 !important;
  color: #485058;
  font-size: 1.2em;
}
.speaker.bio-container {
  font-style: italic !important;
}
.signup {
  min-width: 30vw !important;
}
.card ul {
  text-align: left;
}
.sponsor-logos img {
  border-radius: 15px;
  border: 2px solid #ccc;
}
.header-bg {
  /* background: url("../static/images/dtw2020bg.png"); */
}
.no-border {
  border: 0px !important;
}
.speaker-twitter-handle {
  font-size: 16px !important;
  display: inline-block;
  color: white !important;
  vertical-align: middle;
}
a.badge-link {
  color: white !important;
}
@media (max-width: 576px) {
  .signup {
    min-width: 90vw !important;
  }
}
@media (max-width: 768px) {
  .signup {
    min-width: 90vw !important;
  }
}
